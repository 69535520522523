<template>
  <div class="h-100 position-relative venue-discover overflow-hidden">
    <div
      v-if="$apollo.loading"
      class="h-100 d-flex align-items-center justify-content-center"
    >
      <font-awesome-icon :icon="['fas', 'spinner']" spin size="2x" />
    </div>
    <b-container
      v-else-if="authenticated && discover"
      class="h-100 p-4 position-relative"
    >
      <b-row class="h-100 position-relative">
        <transition-group
          v-if="discover && discover.length"
          name="list"
          tag="div"
          class="row no-gutters"
        >
          <b-col
            v-for="(venue, i) in discover"
            :key="venue.id"
            cols="12"
            lg="8"
            xl="6"
            offset-md="2"
            offset-xl="3"
            class="position-absolute h-100"
            :style="style(venue.id)"
          >
            <b-card
              class="h-100 w-100 shadow-sm"
              body-class="overflow-auto h-100"
            >
              <mdj-discover
                :venue="venue"
                @like="() => onLike(i)"
                @dislike="() => onDislike(i)"
                @pass="() => onPass(i)"
              />
            </b-card>
          </b-col>
        </transition-group>
        <b-col v-else cols="12">
          <div class="my-3">
            <font-awesome-layers class="fa-4x">
              <font-awesome-icon
                icon="circle"
                class="text-primary text-center"
              />
              <font-awesome-icon
                icon="star"
                class="text-white"
                transform="shrink-5"
                style="width: 64px"
              />
            </font-awesome-layers>
          </div>
          <h2 class="title">C'est tout pour aujourd'hui</h2>
          <p class="mb-5">
            D'autres propostions vous seront faites dès demain ! Revenez affiner
            votre sélection
          </p>
        </b-col>
      </b-row>
    </b-container>
    <div
      v-else-if="!authenticated"
      class="h-100 d-flex align-items-center justify-content-center"
    >
      <b-btn variant="primary"> Connectez-vous! </b-btn>
    </div>
    <portal to="content">
      <transition name="swipe">
        <router-view />
      </transition>
    </portal>
  </div>
</template>
<script>
import MDJDiscover from "@/components/Discover";
import DISCOVER from "@/graphql/auth/discover.gql";
import ADVERTISER_DAY from "@/graphql/AdvertiserDay.gql";
export default {
  components: {
    "mdj-discover": MDJDiscover,
  },
  apollo: {
    discover: {
      query: DISCOVER,
      skip: true,
      client: "auth",
    },
  },
  data() {
    return {
      ready: false,
    };
  },
  mounted() {
    if (!this.authenticated) {
      this.$login.login().then(() => {
        this.$apollo.queries.discover.skip = false;
        this.$apollo.queries.discover.refetch();
      });
    } else {
      this.$apollo.queries.discover.skip = false;
      this.$apollo.queries.discover.refetch();
    }
  },
  methods: {
    style(id) {
      let rotate = id % 3;
      if (id % 2 == 1) {
        rotate *= -1;
      }

      return {
        transform: "rotate(" + rotate + "deg)",
      };
    },
    onLike(index) {
      this.discover.splice(index, 1);
    },
    onDislike(index) {
      this.discover.splice(index, 1);
    },
    onPass(index) {
      this.discover.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
.venue-discover {
  .cover {
    height: 200px;
  }
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease-out;
  }

  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(100px) !important;
  }
}
</style>
